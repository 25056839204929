import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import nl2br from "react-nl2br"
import LoadingIcon from "../images/svg/loading.svg"

type RequestState = "succeeded" | "failed" | null

const WhitelistPage: React.FC = () => {
  const whitelistOpen = false
  const [address, setAddress] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [addressRequestState, setAddressRequestState] =
    useState<RequestState>(null)
  const [message, setMessage] = useState("")

  const handleAddress = e => {
    setAddress(e.target.value)
  }

  const handleSearch = e => {
    e.preventDefault()

    if (!address) {
      return
    }

    if (submitting) {
      return
    }

    setSubmitting(true)
    setAddressRequestState(null)
    setMessage("")

    fetch("/.netlify/functions/whitelist", {
      method: "POST",
      body: JSON.stringify({ address }),
    })
      .then(res => {
        if (!res.ok) {
          if (res.status === 404) {
            throw new Error(
              "Your stake address is not on the whitelist.\nPlease mint during public mint."
            )
          }

          throw new Error(
            "Wrong stake address or something else went wrong.\nTry again"
          )
        }

        return res.json()
      })
      .then(({ maxCount }) => {
        setAddressRequestState("succeeded")
        setMessage(
          `Your stake address:\n${address}\nhas ${maxCount} whitelist seats`
        )
        setSubmitting(false)
      })
      .catch(e => {
        setAddressRequestState("failed")
        setMessage(e.message)
        setSubmitting(false)
      })
  }

  return (
    <Layout bgColor="black">
      <SEO title="Whitelist Closed" />

      <div className="flex flex-1 flex-col justify-center space-y-10 overflow-hidden px-4 text-center">
        <h1 className="container mt-8 text-center font-gravity-compressed text-[60px] uppercase leading-none lg:text-[180px]">
          {(whitelistOpen && "Whitelist Check") || "Whitelist closed"}
        </h1>
        {whitelistOpen && (
          <form
            onSubmit={handleSearch}
            className="mx-auto flex w-full max-w-screen-lg flex-col gap-8 px-6"
          >
            <input
              type="text"
              onChange={handleAddress}
              placeholder="Type in your stake address to see your whitelist seats"
              className={`appearance-none border-b border-white bg-transparent py-2 px-1 text-white placeholder-white focus:outline-none ${
                addressRequestState === "succeeded" ? "!border-[#64D841]" : ""
              } ${addressRequestState === "failed" ? "!border-[#FF0000]" : ""}`}
            />
            <div className="mx-auto">
              <button
                type="submit"
                disabled={address === ""}
                className={`group flex appearance-none items-center justify-center rounded-[48px] border border-white px-[60px] py-[20px] font-gravity-compressed text-[18px] uppercase leading-none transition-colors duration-150 ease-in-out hover:bg-white hover:text-black disabled:cursor-not-allowed ${
                  submitting
                    ? "bg-white text-black"
                    : "bg-transparent text-white"
                }`}
              >
                {submitting ? (
                  <div className="flex flex-row items-center">
                    <LoadingIcon className="mr-2 h-5 w-5 animate-spin text-black" />
                    <span>Searching</span>
                  </div>
                ) : (
                  <>Search</>
                )}
              </button>
            </div>
            {message.length ? (
              <div
                className={`break-words sm:break-normal ${
                  addressRequestState === "failed" ? "uppercase" : ""
                }`}
              >
                {nl2br(message)}
              </div>
            ) : null}
          </form>
        )}
      </div>
    </Layout>
  )
}

export default WhitelistPage
